<template>
    <section class="pqr-solicitudes">
        <cargando v-if="cargando" />
        <div class="row mb-4">
            <div class="col-12 col-lg-auto">
                <p class="f-20 f-500">Resoluciones</p>
            </div>
            <div class="col-auto ml-auto my-auto">
                <button class="btn btn-general h-32px f-14 f-300" @click="crearResolucion">
                    Nueva resolución
                </button>
            </div>
            <div class="col-2">
                <el-input v-model="inputSearch" placeholder="Buscar" prefix-icon="el-icon-search" size="small" />
            </div>
        </div>
        <!-- tabla -->
        <div class="row">
            <div class="col-12">
                <el-table :data="resoluciones" height="calc(100vh - 215px)" style="width: 100%" @row-click="verResolucion">
                    <el-table-column sortable prop="titulo" label="Nombre" min-width="100">
                        <template slot-scope="scope">   
                            {{ scope.row.titulo }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="pqrs_activas_count" label="PQRs vigentes" min-width="50" align="center" />
                    <el-table-column sortable prop="pqrs_count" label="PQRs Totales" min-width="50" align="center" />
                    <el-table-column sortable prop="updated_at" label="Editado" min-width="50">
                        <template slot-scope="scope">
                            {{ formatearFecha(scope.row.updated_at,'DD MMM YYYY') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="estado" min-width="50">
                        <template slot-scope="scope">
                            <span v-if="scope.row.estado === 0" class="bg-db rounded-pill px-3 py-1 f-14 text-center">
                                Inactivo
                            </span> 
                        </template>
                    </el-table-column>
                    <div slot="empty" class="row">
                        <div class="col-12 text-center">
                            <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                            <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                        </div>
                    </div>
                    <infinite-loading
                    slot="append"
                    force-use-infinite-wrapper=".el-table__body-wrapper"
                    @infinite="getResoluciones"
                    />
                </el-table>
            </div>
            <!-- <div class="col-12">
                <infinite-loading @infinite="getResoluciones">
                    <div slot="spinner">
                        Consultando...
                    </div>
                    <div slot="no-more" />
                    <div slot="no-results" />
                </infinite-loading>
            </div> -->
        </div>
        <!-- partials -->
        <modal-crear-resolucion ref="modalCrearResolucion" />
    </section>
</template>
<script>
import PQRS from '~/services/pqrs'
import InfiniteLoading from 'vue-infinite-loading';
import ModalCrearResolucion from './partials/modalCrearResolucion.vue';

export default {
    components:{
        modalCrearResolucion: () => import('./partials/modalCrearResolucion'),
        InfiniteLoading,
        ModalCrearResolucion
    },
    data(){
        return{
            page: 1,
            resoluciones: [],
            inputSearch:'',
            cargando: false
        }
    },
    watch: {
        inputSearch(){
            this.buscador()
        }
    },
    methods:{
        crearResolucion(){
            this.$refs.modalCrearResolucion.toggle()
        },
        verResolucion(row){
            this.$router.push({ name: 'pqr.ver.resolucion', params:{id_grupo: row.id} })
        },
        async getResoluciones($state){
            try {
                this.cargando = true
                let params = {
                    page: this.page,
                    search: this.inputSearch
                }

                const state = $state
                const { data } = await PQRS.getResoluciones(params)
                if(data.data.data.length){
                    this.page += 1;
                    this.resoluciones.push(...data.data.data)
                    if(typeof state !== 'undefined'){
                        state.loaded();
                    }
                }else{
                    if(typeof state !== 'undefined'){
                        state.complete();
                    }
                }
            } catch (error){
                this.errorCatch(error)
            } finally{
                this.cargando = false
            }
        },
        async buscador(){
            const busqueda = async() => {
                this.resoluciones = []
                this.page = 1
                this.getResoluciones()
            }
            await this.delay(busqueda)
        },
    }
}
</script>
<style lang="scss" scoped>

</style>