<template>
    <modal ref="modalCrearResolucion" titulo="Nueva resolución" adicional="Guardar" @adicional="guardarResolucion">
        <div class="row mt-4">
            <ValidationObserver ref="formPrincipal" class="w-100">
                <ValidationProvider v-slot="{errors}" tag="div" class="col-12 col-sm-8 mb-3" rules="required|max:60" name="Nombre">
                    <p class="f-12 pl-2 label-inputs">Título</p>
                    <el-input v-model="nombre" placeholder="Nombre" maxlength="60" show-word-limit size="small" class="w-100" />
                    <p class="text-danger f-10">{{ errors[0] }}</p>
                </ValidationProvider>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import PQRS from '~/services/pqrs'
export default {
    data(){
        return{
            nombre: ''
        }
    },
    methods:{
        toggle(){
            this.$refs.modalCrearResolucion.toggle()
        },
        async guardarResolucion(){
            try {
                let validate = await this.$refs.formPrincipal.validate()
                if(!validate) return this.notificacion('','Por favor complete los campos') 

                let params = {
                    titulo: this.nombre,
                }

                const { data } = await PQRS.crearResolucion(params)
                
                this.notificacion('','Resolución guardada correctamente','success')
                this.$router.push({name: 'pqr.ver.resolucion', params:{id_grupo: data.data.id} }) 
                this.$refs.modalCrearResolucion.toggle()

            } catch (error){
                this.errorCatch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.modal-paz-salvo{
    .modal-sm{
        min-width: 390px !important;
    }
}
</style>